   
   .vline {
    border-left: 2px solid white;
    height: 100px;
   }

   .vlineLong {
    border-left: 2px solid white;
    height: 50vh;
   }





  @media (width >= 1024px) {
    .polaroids-container {
      
    }
    .polaroid {
      background: #fff;
      padding: 1rem;
      box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
      
    }
    .polaroid > img{
      max-width: 100%;
      height: auto;
    }
    .caption {
      font-family: 'Poor Story', cursive;
      font-size: 1.8rem;
      text-align: center;
      line-height: 2em;
    }
    .item {
      width: 60%;
      display: inline-block;
      margin-top: 2rem;
    }
    .item .polaroid:before {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.35s;
    }
  }

  @media (width < 1024px) {
    .polaroids-container {

    }
    .polaroid {
      background: #fff;
      padding: 1rem;
      box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
      
    }
    .polaroid > img{
      max-width: 100%;
      height: auto;
    }
    .caption {
      font-family: 'Poor Story', cursive;
      font-size: 0.8rem;
      text-align: center;
      line-height: 2em;
    }
    .item {
      width: 280%;
      display: inline-block;
      margin-top: 2rem;
    }
    .item .polaroid:before {
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.35s;
    }
  }
  


  
  /* .polaroid-small {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    
  }
  .polaroid-small > img{
    max-width: 100%;
    height: auto;
  }
  .caption-small {
    font-family: 'Poor Story', cursive;
    font-size: 0.8rem;
    text-align: center;
    line-height: 2em;
  }
  .item-small {
    width: 140%;
    display: inline-block;
    margin-top: 2rem;
  }
  .item-small .polaroid-small:before {
    content: '';
    position: absolute;
    z-index: -1;
    transition: all 0.35s;
  } */